// import HSHeader from './vendor/hs-header/src/js/hs-header'
import HSMegaMenu from './vendor/hs-mega-menu/dist/hs-mega-menu'
import HSStickyBlock from './vendor/hs-sticky-block/dist/hs-sticky-block'

// // initialize a New HSHeader after the docuemnt is ready
document.addEventListener('DOMContentLoaded', () => {
  // INITIALIZATION OF MEGA MENU
  // =======================================================    
  new HSMegaMenu('.js-mega-menu')

  // INITIALIZATION OF STICKY BLOCKS
  // =======================================================
  new HSStickyBlock('.js-sticky-block', {
    targetSelector: document.getElementById('header').classList.contains('navbar-fixed') ? '#header' : null
  })

  document.addEventListener('DOMContentLoaded', () => {
    // INITIALIZATION OF STICKY BLOCKS
    // =======================================================
    new HSStickyBlock('.sticky-business-detail', {
      targetSelector: '#header'
    })
  })
})
